import { calciteTypeToChartDataType } from "../chart/hql/chartHqlUtils.js";
import { ChartDataType } from "../chart/types.js";

import { ExploreField, ExploreFieldType } from "./types.js";

/**
 * A field is a aggregated if (1) it is a measure or (2) it is a
 * dimension/column that has an aggregation set
 */
export function isAggregatedField(field: ExploreField): boolean {
  if (field.fieldType === ExploreFieldType.MEASURE) {
    return true;
  }
  return field.aggregation != null;
}

export function getFieldActiveScaleType(field: ExploreField): ChartDataType {
  return field.scaleType ?? calciteTypeToChartDataType(field.dataType);
}
